<template>
  <v-row v-if="items && items.length > 0">
    <v-col
      cols="12"
      md="7"
      class="mx-auto"
      v-for="item in items"
      :key="item.id"
    >
      <v-card
        class="rounded-xl"
        elevation="0"
        outlined
        @click="handleBtnClick(item)"
      >
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-subtitle class="my-0 py-0 overline grey--text">
              <span>{{ item.created_at | fromNow }}</span>
            </v-card-subtitle>
            <v-card-title class="title mt-0 pt-0">
              {{
                isHindi
                  ? item.title_hi
                    ? item.title_hi
                    : item.title
                  : item.title
              }}
            </v-card-title>
          </div>

          <v-avatar contain tile class="ma-3 rounded-xl" size="125">
            <v-img :src="item.banner_square" />
          </v-avatar>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="12" class="justify-center text-center">
      {{ loading ? $lang("loading") : $lang("NOITEMS") }}
    </v-col>
  </v-row>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import { Device } from "@capacitor/device";
// import { interstitial } from "@/plugins/admob/interstitial";

export default {
  name: "jobs",
  components: {},
  data() {
    return {
      items: [],
      info: null,
      isAdShown: false,
      loading: false,
    };
  },
  computed: {},
  methods: {
    handleBtnClick(item) {
      this.$router.push({ name: "job", params: { id: item.id } });
    },
    fetchItems() {
      this.loading = true;
      axios
        .get("prep/jobs/public?page=1&orderby=featured|desc")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async created() {
    this.fetchItems();
    this.info = await Device.getInfo();
    if (!this.isAdShown) {
      if (this.info && this.info.platform != "web") {
        // await interstitial();
        this.isAdShown = true;
      }
    }
  },
};
</script>

